import { default as _91id_93KzxcZUCbFhMeta } from "/app/pages/company/[country_code]/[id].vue?macro=true";
import { default as listsh5khcnEktjMeta } from "/app/pages/company/lists.vue?macro=true";
import { default as searchV7DpVOLYyEMeta } from "/app/pages/company/search.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as organizationTSJw3iRAJNMeta } from "/app/pages/organization.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as sign_45inMH4whhaZtqMeta } from "/app/pages/sign-in.vue?macro=true";
export default [
  {
    name: _91id_93KzxcZUCbFhMeta?.name ?? "company-country_code-id",
    path: _91id_93KzxcZUCbFhMeta?.path ?? "/company/:country_code()/:id()",
    meta: _91id_93KzxcZUCbFhMeta || {},
    alias: _91id_93KzxcZUCbFhMeta?.alias || [],
    redirect: _91id_93KzxcZUCbFhMeta?.redirect,
    component: () => import("/app/pages/company/[country_code]/[id].vue").then(m => m.default || m)
  },
  {
    name: listsh5khcnEktjMeta?.name ?? "company-lists",
    path: listsh5khcnEktjMeta?.path ?? "/company/lists",
    meta: listsh5khcnEktjMeta || {},
    alias: listsh5khcnEktjMeta?.alias || [],
    redirect: listsh5khcnEktjMeta?.redirect,
    component: () => import("/app/pages/company/lists.vue").then(m => m.default || m)
  },
  {
    name: searchV7DpVOLYyEMeta?.name ?? "company-search",
    path: searchV7DpVOLYyEMeta?.path ?? "/company/search",
    meta: searchV7DpVOLYyEMeta || {},
    alias: searchV7DpVOLYyEMeta?.alias || [],
    redirect: searchV7DpVOLYyEMeta?.redirect,
    component: () => import("/app/pages/company/search.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organizationTSJw3iRAJNMeta?.name ?? "organization",
    path: organizationTSJw3iRAJNMeta?.path ?? "/organization",
    meta: organizationTSJw3iRAJNMeta || {},
    alias: organizationTSJw3iRAJNMeta?.alias || [],
    redirect: organizationTSJw3iRAJNMeta?.redirect,
    component: () => import("/app/pages/organization.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: sign_45inMH4whhaZtqMeta?.name ?? "sign-in",
    path: sign_45inMH4whhaZtqMeta?.path ?? "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    alias: sign_45inMH4whhaZtqMeta?.alias || [],
    redirect: sign_45inMH4whhaZtqMeta?.redirect,
    component: () => import("/app/pages/sign-in.vue").then(m => m.default || m)
  }
]